export const SOCKET_CONNECTED = 'socketConnected'
export const SOCKET_STATE = 'socketState'
export const LOGOUT = 'logout'
export const VARIABLE = 'variable'
export const CURRENCIES = 'currencies'
export const USER = 'user'
export const USER_PERSONAL = 'userPersonal'
export const TOKEN = 'token'
export const ACCOUNT = 'account'
export const ACCOUNT_ACTIVE = 'accountActive'
export const SET_ACCOUNT = 'setAccount'
export const QUOTE = 'quote'
export const QUOTES = 'quotes'
export const SYMBOL = 'symbol'
export const SYMBOL_REMOVE = 'symbolRemove'
export const BALANCE_UPDATE = 'balanceUpdate'
export const TRANSACTION = 'transaction'
export const FRAME = 'frame'
export const DATA_REFRESH = 'dataRefresh'
export const PSP_LIST = 'pspsList'
export const WITHDRAWAL_LIST = 'withdrawalList'

export const CFD_ORDER = 'cfdOrder'
export const CFD_ORDERS = 'cfdOrders'
export const CFD_HISTORY = 'cfdHistory'
export const CFD_ADD_PROFIT_TOTAL = 'cfdAddProfitTotal'
export const CFD_TRADE_DISABLED = 'cfdTradeDisabled'

export const STAKING_CURRENCY = 'stakingCurrency'
export const STAKING_ACCOUNT = 'stakingAccount'
export const STAKING_ACCOUNT_CLAIMED = 'stakingAccountClaimed'

export const SYMBOL_FAVORITE = 'symbolFavorite'
export const SYMBOL_ACTIVE = 'symbolActive'
export const SYMBOL_ACTIVE_UPDATE = 'symbolActiveUpdate'
export const TIMEFRAME = 'timeframe'

export const MODAL = 'modal'
export const MODAL_MENU = 'modalMenu'
export const MODAL_SYMBOLS = 'modalSymbols'
export const MODAL_EXPIRATIONS = 'modalExpirations'
export const MODAL_AMOUNTS = 'modalAmounts'
export const MODAL_FULLSCREEN = 'modalFullscreen'
export const MODAL_AUTOCLOSE = 'modalAutoClose'
export const MODAL_PENDING = 'modalPending'
export const MODAL_CLOSE_POSITION = 'modalClosePosition'
export const MODAL_ASSETS_INFO = 'modalAssetsInfo'

export const BLUR = 'blur'
export const MUTE = 'mute'
export const LANG = 'lang'
export const LAST_AMOUNTS = 'lastAmounts'

export const GRID_LAYOUT = 'gridLayout'
export const CHART = 'chart'
export const CHART_ACTIVE = 'chartActive'

export const NOTIFICATION = 'notification'
export const NOTIFICATION_ERROR = 'notificationError'
export const NOTIFICATION_REMOVE = 'notificationRemove'
export const NOTIFICATION_ORDER = 'notificationOrder'
export const NOTIFICATION_ORDER_OPEN = 'notificationOrderOpen'
export const NOTIFICATION_SUPPORT = 'notificationSupport'
export const NOTIFICATION_TRANSACTION = 'notificationTransaction'

export const MANAGERS = 'managers'
export const DEPOSITS = 'deposits'
export const WITHDRAWALS = 'withdrawals'
export const DOCUMENTS = 'documents'
export const DEPOSIT_ADDRESS = 'depositAddress'
export const DEPOSIT_TRANSACTION = 'depositTransaction'
export const OTP_ENABLE_DATA = 'otpEnableData'

export const SYMBOL_SHOW = 'symbolShow'
export const SYMBOL_HIDE = 'symbolHide'

export const REFERRALS_PAYOUTS = 'referralsPayouts'
export const REFERRALS_REFERRALS = 'referralsReferrals'

export const SUPPORT_MESSAGE = 'supportMessage'
export const SUPPORT_UNREAD = 'supportUnread'

export const TROLLBOX_MESSAGE = 'trollboxMessage'

export const ANNOUNCEMENT = 'announcement'

export const ORDERS_PANE = 'ordersPane'

export const CONVERSION = 'conversion'

export const NEWS = 'news'

export const QUESTIONS = 'questions'

export const OTHER_PSPS = 'otherPsps'

export const HISTORY_TABLE_IS_ACTIVE = 'historyTableIsActive'

export const DASHBOARD_LOGIN = 'dashboardLogin'
export const DASHBOARD_STATS = 'dashboardStats'
export const DASHBOARD_BALANCE_CHART = 'dashboardBalance_chart'

export const BANK_DEPOSIT = 'bankDeposit'

export const ACTIVE_CURRENCY = 'active_currency'

export const TIME_SHIFT = 'timeShift'
// export const SET_SESSION_TIME = 'set_sessions_time'
export const SESSIONS_STATE = 'sessionsState'

export const MODAL_WITHDRAWAL_CANCEL = 'modalWithdrawalCancel'
export const MODAL_QR_CODE = 'modalQrCode'
export const SHOW_WEB_NOTIFICATION = 'showWebNotification'
export const CHECK_NOTIFICATION_PROMISE = 'checkNotificationPromise'
export const SET_TRANSACTION_ID = 'setTransactionId'
export const SET_ROBOT_AI = 'setRobotAi'
export const SET_AUTOCLOSE_MODE = 'setAutocloseMode'
export const SET_AUTOTRADER_CONFIG = 'setAutoTraderConfig'
export const SORT_ORDERS = 'sortOrders'
export const SORT_HISTORY_ORDERS = 'sortHistoryOrders'
export const SET_STATIC_BASE = 'setStaticBase'
export const SET_BANKS_VARIABLES = 'setBanksVariables'
export const SET_LIGHT_THEME = 'setLightTheme'
export const SET_THEME_COLORS = 'setThemeColors'
export const MODAL_TIME_PICKER = 'modalTimePicker'
export const SET_PREV_PAGE = 'setPrevPage'
